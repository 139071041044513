import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export let ROUTES: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-tv-2 text-info', class: '' },
  { path: '/dashboard', title: 'Principal', icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/casos', title: 'Casos Disponibles', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/casosvistos', title: 'Casos Vistos', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/miscasos', title: 'Mis Casos', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/clientes', title: 'Mis Clientes', icon: 'ni-circle-08 text-warning', class: '' },
  { path: '/user-profile', title: 'Mi Perfil', icon: 'ni-single-02 text-yellow', class: '' },
  { path: '/logout', title: 'Salir', icon: 'ni-user-run text-info', class: '' },
];
export const ROUTESCLIENTE: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-tv-2 text-info', class: '' },
  { path: '/gssolicitudescliente', title: 'Mis Solicitudes', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gsexpedicionescli', title: 'Mis Pólizas', icon: 'ni-briefcase-24 text-success', class: '' },
  { path: '/gsusuariocl', title: 'Mi Perfil', icon: 'ni-single-02 text-yellow', class: '' },
  { path: '/logout', title: 'Salir', icon: 'ni-user-run text-info', class: '' },
];
export const ROUTESNOVALIDADO: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-tv-2 text-info', class: '' },
  { path: '/userprofilecliente', title: 'Mi Perfil', icon: 'ni-single-02 text-yellow', class: '' },
  { path: '/logout', title: 'Salir', icon: 'ni-user-run text-info', class: '' },
];
export const ROUTESASESOR: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-tv-2 text-info', class: '' },
  { path: '/gssolicitudesdisp', title: 'Oportunidades Disponibles', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gssolicitudesvistas', title: 'Oportunidades Vistas', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gscotizacionesaceptadas', title: 'Cotizaciones Aceptadas', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gsexpediciones', title: 'Expediciones', icon: 'ni-briefcase-24 text-success', class: '' },
  /*  { path: '/gscomercial', title: 'Comerciales', icon: 'ni-briefcase-24 text-cyan', class: '' },
  { path: '/gscliente', title: 'Clientes', icon: 'ni-briefcase-24 text-info', class: '' }, */
 /*  { path: '/cotizador', title: 'Cotizador', icon: 'ni-briefcase-24 text-info', class: '' },  */
 { path: '/cotizador', title: 'Cotizador', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gsusuario', title: 'Mi perfil', icon: 'ni-briefcase-24 text-success', class: '' },
  { path: '/logout', title: 'Salir', icon: 'ni-user-run text-info', class: '' },
];
export const ROUTESCOMERCIAL: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-tv-2 text-info', class: '' },
  { path: '/gssolicitudesdisp', title: 'Oportunidades Disponibles', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gssolicitudesvistas', title: 'Oportunidades Vistas', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gscotizacionesaceptadas', title: 'Cotizaciones Aceptadas', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gscliente', title: 'Clientes', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gsusuario', title: 'Mi perfil', icon: 'ni-briefcase-24 text-success', class: '' },
  { path: '/logout', title: 'Salir', icon: 'ni-user-run text-info', class: '' },
];
export const ROUTESADMIN: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-tv-2 text-info', class: '' },
  { path: '/dashboard', title: 'Principal', icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/productos', title: 'Productos', icon: 'ni-briefcase-24 text-primary', class: '' },
  { path: '/gslandingproductos', title: 'Landing Productos', icon: 'ni-briefcase-24 text-primary', class: '' },
  { path: '/gsproductosaseguradoras', title: 'Productos Aseguradoras', icon: 'ni-briefcase-24 text-primary', class: '' },
  { path: '/aseguradoras', title: 'Aseguradoras', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/productoscotizador', title: 'Crear Cotizador', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gsadministrador', title: 'Administradores', icon: 'ni-briefcase-24 text-teal', class: '' },
  { path: '/gsasesor', title: 'Asesores', icon: 'ni-briefcase-24 text-teal', class: '' },
  { path: '/gscomercial', title: 'Comerciales', icon: 'ni-briefcase-24 text-cyan', class: '' },
  { path: '/gscliente', title: 'Clientes', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gssolicitudes', title: 'Oportunidades', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gssolicitudesdisp', title: 'Oportunidades Disponibles', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gssolicitudesvistas', title: 'Oportunidades Vistas', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gscotizacionesaceptadas', title: 'Cotizaciones Aceptadas', icon: 'ni-briefcase-24 text-info', class: '' },
  { path: '/gscotizaciones', title: 'Cotizaciones', icon: 'ni-briefcase-24 text-success', class: '' },
  { path: '/gsusuario', title: 'Mi perfil', icon: 'ni-briefcase-24 text-success', class: '' },


  { path: '/logout', title: 'Salir', icon: 'ni-user-run text-info', class: '' },
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  userList: any[];

  constructor(
    private userSer: UserService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userSer.getUserByField("email", user.email).subscribe((usersSnapshot) => {
          usersSnapshot.forEach((userSnapshot: any) => {
            const userlog = userSnapshot.payload.doc.data();
            if (userlog.isAdmin) {
              ROUTES = ROUTESADMIN;
            } else if (userlog.isAsesor) {
              ROUTES = ROUTESASESOR;

            }  else if (userlog.isComercial) {
              ROUTES = ROUTESCOMERCIAL;

            } else {
              ROUTES = ROUTESCLIENTE;
            }
            this.menuItems = ROUTES.filter(menuItem => menuItem);
            this.router.events.subscribe((event) => {
              this.isCollapsed = true;
            });
          })
        });
      } else {

        this.ngZone.run(() => {
          this.router.navigate(['']);
        })
      }
    });
  }
}
