import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { LandingComponent } from './pages/landing/landing.component';
import { AddCasoComponent } from './pages/add-caso/add-caso.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule } from '@angular/fire';
import { FireBaseConfig } from 'src/environments/firebase.config';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NavbarlandingComponent } from './components/navbarlanding/navbarlanding.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddSolicitudComponent } from './pages/add-solicitud/add-solicitud.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { AddProductosComponent } from './pages/add-productos/add-productos.component';
import { AseguradorasComponent } from './pages/aseguradoras/aseguradoras.component';
import { AddAseguradorasComponent } from './pages/add-aseguradoras/add-aseguradoras.component';
import { AddUsuariosComponent } from './pages/add-usuarios/add-usuarios.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { AddAdministradorComponent } from './pages/add-administrador/add-administrador.component';
import { AddAsesorComponent } from './pages/add-asesor/add-asesor.component';
import { AddComercialComponent } from './pages/add-comercial/add-comercial.component';
import { AddClienteComponent } from './pages/add-cliente/add-cliente.component';
import { AddPlanComponent } from './pages/add-plan/add-plan.component';
import { PlanComponent } from './pages/plan/plan.component';
import { SolicitudesComponent } from './pages/solicitudes/solicitudes.component';
import { LandingProductoComponent } from './pages/landing-producto/landing-producto.component';
import { AddLandingProductoComponent } from './pages/add-landing-producto/add-landing-producto.component';
import { AdminLandingProductoComponent } from './pages/admin-landing-producto/admin-landing-producto.component';
import { CotizacionesComponent } from './pages/cotizaciones/cotizaciones.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UserProfileClientComponent } from './pages/user-profile-client/user-profile-client.component';
import { SolicitudesDisponiblesComponent } from './pages/solicitudes-disponibles/solicitudes-disponibles.component';
import { SolicitudesVistasComponent } from './pages/solicitudes-vistas/solicitudes-vistas.component';
import { MisSolicitudesComponent } from './pages/mis-solicitudes/mis-solicitudes.component';
import { ProductosAseguradorasComponent } from './pages/productos-aseguradoras/productos-aseguradoras.component';
import { AddProductosAseguradorasComponent } from './pages/add-productos-aseguradoras/add-productos-aseguradoras.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CotizacionesAceptadasComponent } from './pages/cotizaciones-aceptadas/cotizaciones-aceptadas.component';
import { AdminEtapasComponent } from './pages/admin-etapas/admin-etapas.component';
import { AddEtapasComponent } from './pages/add-etapas/add-etapas.component';
import { AddSolicitudDinamicComponent } from './pages/add-solicitud-dinamic/add-solicitud-dinamic.component';

import { FormlyMaterialModule } from '@ngx-formly/material';
import { AddFormularioDinamicComponent } from './pages/add-formulario-dinamic/add-formulario-dinamic.component';
import { FormlyDesignerModule, FormlyDesignerService } from 'ngx-formly-designer';
import { FormlyTabsModule } from 'ngx-formly-tabs';
import { designerConfig } from './formularios-dinamicos/designer-config';
import { FormlyModule } from '@ngx-formly/core';
import { ExpanderComponent } from './formularios-dinamicos/components/expander.component';
import { config, fieldComponents } from './formularios-dinamicos/config';
import { LandingIntermediariosComponent } from './pages/landing-intermediarios/landing-intermediarios.component';
import { FormularioSolicitudesComponent } from './pages/formulario-solicitudes/formulario-solicitudes.component';
import { ExpedicionesComponent } from './pages/expediciones/expediciones.component';
import { ExpedicionesClienteComponent } from './pages/expediciones-cliente/expediciones-cliente.component';
import { RegisterComponent } from './pages/register/register.component';
import { CotizadorComponent } from './pages/cotizador/cotizador.component';
import { AddCotizacionCotizadorComponent } from './pages/add-cotizacion-cotizador/add-cotizacion-cotizador.component';
import { AddProductosCotizadorComponent } from './pages/add-productos-cotizador/add-productos-cotizador.component';
import { ProductosCotizadorComponent } from './pages/productos-cotizador/productos-cotizador.component';
import { InfoPortalesComponent } from './pages/info-portales/info-portales.component';
import { AddPortalesComponent } from './pages/add-portales/add-portales.component';
import { VisorComparacionComponent } from './pages/visor-comparacion/visor-comparacion.component';
import { ListaMaquinasComponent } from './pages/lista-maquinas/lista-maquinas.component';




@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(FireBaseConfig),
    AngularFireDatabaseModule,
    FontAwesomeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    AngularFireStorageModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatBadgeModule,
    MatListModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormlyModule.forRoot(config),
    FormlyTabsModule,
    FormlyMaterialModule,
    FormlyDesignerModule.forRoot(designerConfig),
   
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LandingComponent,
    AddCasoComponent,
    NavbarlandingComponent,
    AddSolicitudComponent,
    ProductosComponent,
    AddProductosComponent,
    AseguradorasComponent,
    AddAseguradorasComponent,
    AddUsuariosComponent,
    UsuariosComponent,
    AddAdministradorComponent,
    AddAsesorComponent,
    AddComercialComponent,
    AddClienteComponent,
    AddPlanComponent,
    PlanComponent,
    SolicitudesComponent,
    LandingProductoComponent,
    AddLandingProductoComponent,
    AdminLandingProductoComponent,
    CotizacionesComponent,
    UserProfileComponent,
    UserProfileClientComponent,
    SolicitudesDisponiblesComponent,
    SolicitudesVistasComponent,
    MisSolicitudesComponent,
    ProductosAseguradorasComponent,
    AddProductosAseguradorasComponent,
    CotizacionesAceptadasComponent,
    AdminEtapasComponent,
    AddEtapasComponent,
    AddSolicitudDinamicComponent,
    AddFormularioDinamicComponent,
    ExpanderComponent,
    fieldComponents,
    LandingIntermediariosComponent,
    FormularioSolicitudesComponent,
    ExpedicionesComponent,
    ExpedicionesClienteComponent,
    RegisterComponent,
    CotizadorComponent,
    AddCotizacionCotizadorComponent,
    AddProductosCotizadorComponent,
    ProductosCotizadorComponent,
    InfoPortalesComponent,
    AddPortalesComponent,
    VisorComparacionComponent,
    ListaMaquinasComponent
    
    
  ],
  providers: [FormlyDesignerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
