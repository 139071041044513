import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { AngularFirestore } from "@angular/fire/firestore";

import * as moment from "moment";
import { User } from "../model/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  selectedUser: User = new User();
  users: AngularFireList<User>;

  location = {
    lat: null,
    lon: null,
  };

  constructor(
    private db: AngularFireDatabase, 
    private firestrore: AngularFirestore
  ) {
    this.getUsers();
  }

  getUsers() {
    return this.firestrore.collection('usuarios').snapshotChanges();
  }

  getUserById(id: string) { 
    return this.firestrore.collection('usuarios').doc(id);
  }

  getUserByField(campo: string, valorCampo: string) {
    return this.firestrore.collection('usuarios', ref => ref.where(campo, '==', valorCampo)).snapshotChanges();
  }

  getUserByValidar() {
    return this.firestrore.collection('usuarios', ref => ref.where('docvalidado', '==', false)).snapshotChanges();
  }

  createUser(data: any, cliente: boolean) {
    data.location = this.location;
    data.createdOn = moment(new Date()).format("X");
    data.isAdmin = false;
    data.isAsesor = false;
    data.isComercial = false;
    data.isCliente = cliente;
    this.firestrore.collection('usuarios').add(data);
  }

  isAdmin(emailId: string) {
    return this.db.list("usuarios", (ref) =>
      ref.orderByChild("email").equalTo(emailId)
    );
  }

  updateUser(data: any, idUser: string) {
    return this.firestrore.collection("usuarios").doc(idUser).update(data);
  }

  delete(userId: string) {
    this.firestrore.doc("usuarios" + '/' + userId).delete();
  }

  setLocation(lat, lon) {
    this.location.lat = lat;
    this.location.lon = lon;
  }
}
