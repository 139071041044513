import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  public userList = [];
  constructor(
    location: Location,  
    private userSer: UserService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
    ) {
    this.location = location;
   
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userSer.getUserByField("email", user.email).subscribe((usersSnapshot) => {
          usersSnapshot.forEach((userSnapshot: any) => {
            this.userList = [];
            this.userList.push({
              id: userSnapshot.payload.doc.id,
              data: userSnapshot.payload.doc.data()
            });
          })
        });
      } else {
        this.ngZone.run(() => {
          this.router.navigate(['']);
        })
      }
    });
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }
  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return '';
  }

  
}
