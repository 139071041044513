export class User {
  $key: string;
  userName: string;
  email: string;
  password?: string;
  location?: {
    lat: number;
    lon: number;
  };
  phoneNumber: number;
  createdOn?: string;
  isAdmin: boolean;
  isAbogado: boolean;
  avatar?: string;
  direccion: string;
  acercademi: string;
  departamento: string;
  ciudad: string;
  given_name: string;
  family_name: string;
  cargo: string;
  empresa: string;
  profesion: string;
  universidad: string;
  plan: number;
  inicioplan: string;
  finplan: string;
  categorias: string[];
}

export class UserDetail {
  $key: string;
  firstName: string;
  lastName: string;
  userName: string;
  emailId: string;
  address1: string;
  address2: string;
  country: string;
  state: string;
  zip: number;
}

export class VistasOportunidades {
  fechaVista: string;
  idAsesor: string;
}

export class ContactosAsesor {
  fechaVista: string;
  idAsesor: string;
  idCliente: string;
}