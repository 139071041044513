import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarruselComponent } from './carrusel/carrusel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ListcategoriasComponent } from './listcategorias/listcategorias.component';
import { LogoutComponent } from './logout/logout.component';
import { TarjetaabogadoComponent } from './tarjetaabogado/tarjetaabogado.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DashboardIndicadoresComponent } from './dashboard-indicadores/dashboard-indicadores.component';
import { CotizacionesListComponent } from './cotizaciones-list/cotizaciones-list.component';
import { AseguradorasListComponent } from './aseguradoras-list/aseguradoras-list.component';
import { ProductosListComponent } from './productos-list/productos-list.component';
import { CalificacionComponent } from './calificacion/calificacion.component';
import { AseguradorasLogoComponent } from './aseguradoras-logo/aseguradoras-logo.component';
import { DetalleSolicitudComponent } from './detalle-solicitud/detalle-solicitud.component';
import { CargarCotizacionComponent } from './cargar-cotizacion/cargar-cotizacion.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { VerCotizacionComponent } from './ver-cotizacion/ver-cotizacion.component';
import { CaracteristicasComponent } from './caracteristicas/caracteristicas.component';
import { CargarExpedicionComponent } from './cargar-expedicion/cargar-expedicion.component';
import { ComoFuncionaComponent } from './como-funciona/como-funciona.component';
import { PorqueNosotrosComponent } from './porque-nosotros/porque-nosotros.component';
import { VideoIntermediarioComponent } from './video-intermediario/video-intermediario.component';
import { VideoClienteComponent } from './video-cliente/video-cliente.component';
import { ReviewComponent } from './review/review.component';
import { MegamenuComponent } from './megamenu/megamenu.component';
import { VerCotizacionesBotComponent } from './ver-cotizaciones-bot/ver-cotizaciones-bot.component';
import { ChatComponent } from './chat/chat/chat.component';
import {MatCardModule} from '@angular/material/card';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        CarouselModule,
        MatDialogModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatBadgeModule,
        MatListModule,
        MatGridListModule,
        MatRadioModule,
        MatDatepickerModule,
        MatChipsModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        PdfViewerModule,
        DragDropModule,
        FormsModule,
        MatCardModule
    ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CarruselComponent,
    LogoutComponent,
    ListcategoriasComponent,
    TarjetaabogadoComponent,
    PdfviewerComponent,
    DashboardIndicadoresComponent,
    CotizacionesListComponent,
    AseguradorasListComponent,
    ProductosListComponent,
    CalificacionComponent,
    AseguradorasLogoComponent,
    DetalleSolicitudComponent,
    CargarCotizacionComponent,
    VerCotizacionComponent,
    CaracteristicasComponent,
    CargarExpedicionComponent,
    ComoFuncionaComponent,
    PorqueNosotrosComponent,
    VideoIntermediarioComponent,
    VideoClienteComponent,
    ReviewComponent,
    MegamenuComponent,
    VerCotizacionesBotComponent,
    ChatComponent



  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CarruselComponent,
    LogoutComponent,
    ListcategoriasComponent,
    DashboardIndicadoresComponent,
    CotizacionesListComponent,
    AseguradorasListComponent,
    ProductosListComponent,
    CalificacionComponent,
    AseguradorasLogoComponent,
    CargarCotizacionComponent,
    ComoFuncionaComponent,
    PorqueNosotrosComponent,
    VideoIntermediarioComponent,
    VideoClienteComponent,
    MegamenuComponent

  ]
})
export class ComponentsModule { }
