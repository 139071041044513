<div class="main-content">
    <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark fixed-top posTop">
        <div class="container px-4">
            <a class="navbar-brand" routerLinkActive="active" [routerLink]="['/']">

            </a>
            <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed" aria-controls="sidenav-collapse-main">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <a routerLinkActive="active" [routerLink]="['/']">

                            </a>
                        </div>
                        <div class="col-6 collapse-close">
                            <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <img src="./assets/img/brand/logoL3.png" class="img-fluid texto-barra logo-visible-s logo-invisible-l">
                </div>
                <!-- Navbar items -->
                <img src="./assets/img/brand/red.png" class="img-fluid logo-visible-l logo-invisible-s">
                <ul class="navbar-nav ml-auto">

                    <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                        <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link nav-link-icon">
                            <i class="ni {{menuItem.icon}} text-secondary text-info-landing texto-barra"></i>
                            <span class="nav-link-inner--text text-secondary text-info-landing texto-barra"> {{menuItem.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </nav>
</div>