import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  onclick : string;
}

export let ROUTES: RouteInfo[] = [
  { path: '/landing', title: 'Personas', icon: 'ni-single-02', class: '', onclick: '' },
  /* { path: '/empresa', title: 'Empresas', icon: 'ni-building', class: '', onclick: '' },*/
  { path: '/marketinfo', title: 'Eres Asesor?', icon: 'ni-briefcase-24', class: '', onclick: '' }, 
  { path: '/login', title: 'Login', icon: 'ni-key-25', class: '', onclick: '' },
];
export let ROUTES_LOGIN: RouteInfo[] = [
  { path: '/', title: 'Inicio', icon: 'ni-building', class: '', onclick: '' },
  /* { path: '/marketinfo', title: 'Eres Asesor?', icon: 'ni-briefcase-24', class: '', onclick: '' }, */
  { path: '/dashboard', title: 'Principal', icon: 'ni-single-02 ', class: '', onclick: '' },
  { path: '/logout', title: 'Salir', icon: 'ni-user-run', class: '', onclick: '(click)="authenticationService.SignOut()"' },
];
export let ROUTES_LOGOUT: RouteInfo[] = [
  { path: '/landing', title: 'Personas', icon: 'ni-single-02', class: '', onclick: '' },
  /* { path: '/empresa', title: 'Empresas', icon: 'ni-building', class: '', onclick: '' },*/
  { path: '/marketinfo', title: 'Eres Asesor?', icon: 'ni-briefcase-24', class: '', onclick: '' }, 
  { path: '/login', title: 'Login', icon: 'ni-key-25', class: '', onclick: '' },
];
export let ROUTES_PRODUCTO: RouteInfo[] = [
];

@Component({
  selector: 'app-navbarlanding',
  templateUrl: './navbarlanding.component.html',
  styleUrls: ['./navbarlanding.component.css']
})
export class NavbarlandingComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  public userList = [];

  constructor(
    private userSer: UserService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    var str = this.router.url;
    if(str.includes("/lproduct")){
      ROUTES = ROUTES_PRODUCTO;
    } else{
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.userSer.getUserByField("email", user.email).subscribe((usersSnapshot) => {
            usersSnapshot.forEach((userSnapshot: any) => {
              ROUTES = ROUTES_LOGIN;
              
            })
          });
        } else {
          ROUTES = ROUTES_LOGOUT;   
        }
        
        this.menuItems = ROUTES.filter(menuItem => menuItem);
              this.router.events.subscribe((event) => {
                this.isCollapsed = true;
              });
      });
  
    }
  }
}
