import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookService, InitParams } from 'ngx-facebook';


@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;

  constructor(
    private router: Router, 
    private facebookService: FacebookService) { 
   
    }

  ngOnInit() {
   
    var html = document.getElementsByTagName("html")[0];
    html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }
  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
  }
  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v8.0' };
    this.facebookService.init(initParams);
  }

 
}
