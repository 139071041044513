export const FireBaseConfig = {
  apiKey: "AIzaSyBZOdOQIfx3IzpxYp73GeStdkbsOp9MdY4",
  authDomain: "landingmasseguros.firebaseapp.com",
  databaseURL: "https://landingmasseguros.firebaseio.com",
  projectId: "landingmasseguros",
  storageBucket: "landingmasseguros.appspot.com",
  messagingSenderId: "103394664139",
  appId: "1:103394664139:web:ca37fe327f7198ba96c54e",
  measurementId: "G-PGC9DZNGTV"
};/*  
 export const FireBaseConfig = {
  apiKey: "AIzaSyDopiwy8_n5l4u1Wi6xlPYzNd0E6ktomIc",
  authDomain: "massegurosdesarrollo.firebaseapp.com",
  projectId: "massegurosdesarrollo",
  storageBucket: "massegurosdesarrollo.appspot.com",
  messagingSenderId: "258072420012",
  appId: "1:258072420012:web:4470243eb0605ae4764909",
  measurementId: "G-QS135Y1VSG"
};  */