import { ConfigOption } from '@ngx-formly/core';
import { FormlyGroup } from '@ngx-formly/core/lib/templates/formly.group';
import { FormlyFieldMultiCheckbox } from '@ngx-formly/material/multicheckbox';
import { FormlyFieldSelect } from '@ngx-formly/material/select';

import { FormlyFieldCustomInputComponent } from './types/custom-input';
import { FormlyFieldRepeatSectionComponent } from './types/repeat-section';
import { FormlyWrapperExpanderComponent } from './wrappers/expander';

export const fieldComponents = [
  FormlyFieldCustomInputComponent,
  FormlyFieldRepeatSectionComponent,
  FormlyWrapperExpanderComponent
];

export const config: ConfigOption = {
  types: [
    { name: 'extended-input', extends: 'input' },
    { name: 'customInput', component: FormlyFieldCustomInputComponent },
    { name: 'repeatSection', component: FormlyFieldRepeatSectionComponent },
    
    {
      name: 'multicheckbox', component: FormlyFieldMultiCheckbox,
      defaultOptions: {
        "className": "col-6",
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'select', component: FormlyFieldSelect,
      defaultOptions: {
        "className": "col-6",
        templateOptions: {
          options: []
        }
      }
    }
  ],
  wrappers: [
    { name: 'expander', component: FormlyWrapperExpanderComponent }
  ]
};